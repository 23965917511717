@import url("https://fonts.googleapis.com/css2?family=Anek+Latin:wght@300;400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Anek Latin", system-ui, sans-serif;
  }

  .teach > ul > li.ml-4 {
    margin-left: auto;
  }
}
